<template>
<div class="voucher">
  <div id="printSection">
    <table class="table-print" style="width:80%; margin:5 auto;">
      <tr>
              <td align=center>
      <img alt="finkk logo" src="../assets/flinkk-logo-xs.png" />
    
              </td>
            </tr>
            </table>
    <br>
    <br>
    <table class="table-print" style="width:80%; margin:5 auto; border-width: 1px; border-style: solid none">
      <tr>
        <td style="vertical-align:top">Ihre <b>Zugangsdaten</b>:</td>
        <td> 
         <table class="table-printcontent">
            <tr>
              <td ><b>Dauer:</b></td>
              <td >
                <b><span> {{ getComputedExpireDays() }}</span> </b>
              </td>
            </tr>
            <tr>
              <td ><b>Nutzer:</b></td>

              <td >
                <b><span style="font-family: monospace"> {{ username }}</span></b>
              </td>
            </tr>
            <tr>
              <td ><b>Passwort:</b></td>

              <td>
                <b><span style="font-family: monospace"> {{ password }}</span></b>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
  
  <div >
    <table class="table">      
      <tr>
        <td class="text-center">
          <button
            v-on:click="print"
            class="btn btn-lg btn-primary pull-xs-right"
          >
            Drucken
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
</template>

<script>
import { BOOKING_VOUCHER_RESET } from "@/store/actions.type";
export default {
  name: "Voucher",
  props: {
    username: { type: String, required: true },
    password: { type: String, required: true },
    expireDays: { type: Number, required: true },
  },
  methods: {
    print() {
      this.$htmlToPaper("printSection");
      this.$store.dispatch(BOOKING_VOUCHER_RESET);
    },
    getComputedExpireDays() {
      var value = "";
      if (this.expireDays == 0) {
         value = "Probe - 30 Minuten";
      }
      else if (this.expireDays == 1) {
         value = "" + this.expireDays + " Tag";
      } else if (this.expireDays < 28) {
         value = "" + this.expireDays + " Tage";
      } else if (this.expireDays <= 31) {
         value = "1 Monat";
      } else if (this.expireDays < 362) {
         value = "" + Math.floor(this.expireDays/30) + " Monate";
      } else if (this.expireDays > 362) {
         value = "1 Jahr";
      }
       return value;
    }
  },
};
</script>
