<template>
  <div class="home-page">
    <div class="container page">
      <div v-if="checkAuthenticate()">
        <div class="row">
          <div class="col-md-9">
            <BookingVoucher />
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <div v-if="voucherData.expireDays != -1">
              <Voucher
                :username="voucherData.username"
                :password="voucherData.password"
                :expireDays="voucherData.expireDays"
              >
              </Voucher>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import BookingVoucher from "@/components/BookingVoucher";
import Voucher from "@/components/Voucher";

import { LOGOUT } from "@/store/actions.type";

export default {
  name: "home",
  components: {
    BookingVoucher,
    Voucher,
  },
  methods: {
    checkAuthenticate() {
      if (this.isAuthenticated) {
        return true;
      } else {
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "voucherData",
      "getCampingplaces",
      "currentUser",
      "getReportings",
      "isAdmin",
    ]),
  },
};
</script>
