<template>
  <div class="booking-voucher">
    <div class="container page">
      <h1>Voucher buchen für {{ currentUser.campingPlacename }}</h1>
      <table class="table">
        <tr>
          <td class="align-middle">
            <button
              v-on:click="bookingVoucher(0)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              Probe
            </button> 
            <button
              v-on:click="bookingVoucher(1)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              1 Tag
            </button>            
            <button
              v-on:click="bookingVoucher(3)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              3 Tage
            </button>
            <button
              v-on:click="bookingVoucher(7)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              1 Woche
            </button>
          </td>
        </tr> 
        <tr>
          <td class="align-middle">
            <button
              v-on:click="bookingVoucher(14)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              2 Wochen
            </button>
            <button
              v-on:click="bookingVoucher(30)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              1 Monat
            </button>
            <button
              v-on:click="bookingVoucher(182)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              6 Monate
            </button>
            <button
              v-on:click="bookingVoucher(365)"
              class="btn-voucher btn-lg btn-primary pull-xs-right">
              1 Jahr
            </button>
          </td>
        </tr>
      </table>
      <br />
    </div>
  </div>
</template>

<script>
import { BOOKING_VOUCHER } from "@/store/actions.type";
import { mapGetters } from "vuex";
export default {
  name: "BookingVoucher",
  methods: {
    bookingVoucher(id) {
      let bookingInfo = { days: id, username: this.currentUser.username };
      this.$store.dispatch(BOOKING_VOUCHER, bookingInfo);
    }
  },
  computed: {
    ...mapGetters(["currentUser", "isAuthenticated"]),
  },
};
</script>
